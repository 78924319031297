import { gql } from '@apollo/client'

const LIST_INVOICES_FROM_GOVBOOKS = gql`
  query ListInvoicesFromGovBooks($date: String, $invTransactionType: String, $page: Int) {
    listInvoicesFromGovBooks(listInvoicesFromGovBooksInput: { date: $date, invTransactionType: $invTransactionType, page: $page }) {
      maxPage
      currentPage
      perPage
      invoices {
        TypeOfInv
        IsSimplifiedInv
        IssueDateTime
        InvOrdNum
        TCRCode
        IsIssuerInVAT
        TotVATAmt
        TotPrice
        OperatorCode
        BusinUnitCode
        SoftCode
        IIC
        FIC
        IsReverseCharge
        IsEinvoice
        SupplyDateOrPeriod {
          startDate
          endDate
        }
        PayDeadline
        CorrectiveInv {
          IICRef
          IssueDateTime
        }
        Seller {
          IDType
          IDNum
          Name
          Address
          Town
          Country
        }
        Buyer {
          IDType
          IDNum
          Name
          Address
          Town
          Country
        }
      }
    }
  }
`;



const GET_INVOICE_BY_FIC_FROM_GOVBOOKS = gql`
  query GetInvoiceByFICFromGovBooks($invTransactionType: String!, $FIC: String!) {
    getInvoiceByFICFromGovBooks(getInvoiceByFICFromGovBooksInput: { invTransactionType: $invTransactionType, FIC: $FIC }) {
      invoice {
        TypeOfInv
        IsSimplifiedInv
        IssueDateTime
        SendDateTime
        InvNum
        InvOrdNum
        TCRCode
        IsIssuerInVAT
        TotPriceWoVAT
        TotVATAmt
        TotPrice
        OperatorCode
        BusinUnitCode
        SoftCode
        IIC
        FIC
        IsReverseCharge
        PayDeadline
        IsEinvoice
        SupplyDateOrPeriod {
          startDate
          endDate
        }
        PayMethods {
          Type
          Amt
        }
        CorrectiveInv {
          IICRef
          IssueDateTime
        }
        Seller {
          IDType
          IDNum
          Name
          Address
          Town
          Country
        }
        Buyer {
          IDType
          IDNum
          Name
          Address
          Town
          Country
        }
        Items {
          ItemName
          Code
          Unit
          Quantity
          UnitPriceBefVAT
          UnitPriceAftVAT
          PriceBefVAT
          VATRate
          VATAmount
          PriceAftrVAT
        }
        SameTaxes {
          NumOfItems
          PriceBefVAT
          VATRate
          VATAmt
        }
      }
    }
  }
`
export { GET_INVOICE_BY_FIC_FROM_GOVBOOKS, LIST_INVOICES_FROM_GOVBOOKS }