import { isArray, isEmpty } from 'lodash'
import moment from 'moment'

const GovInvoicesMapper = (res) => {
    const { invoices = [] } = res?.listInvoicesFromGovBooks
    const mappedResponse = []
    const mapByDate = {}

    if (!isArray(invoices)) {
        return []
    } else {
        invoices.forEach((invoice, index) => {
            const mappedInvoice = {

            }
            //for list
            const issueDateTime = invoice?.IssueDateTime || '-'
            const dateFormatted = moment(issueDateTime).format('MMM DD, YYYY')
            mappedInvoice.id = invoice?.InvOrdNum || '-'
            mappedInvoice.title = invoice?.InvOrdNum || '-'
            mappedInvoice.email = invoice?.Buyer?.Name || '-'
            mappedInvoice.time = moment(issueDateTime).format('hh:mm A') || '-'
            mappedInvoice.expectedCash = `+ ${invoice?.TotPrice || '-'} ALL`
            mappedInvoice.FIC = invoice?.FIC || ''



            if (mapByDate[dateFormatted]) {
                mapByDate[dateFormatted].push(mappedInvoice)
            } else {
                mapByDate[dateFormatted] = [mappedInvoice]
            }
        })
    }

    const orderedDate = Object.keys(mapByDate).sort((a, b) => b > a ? 1 : -1)
    orderedDate.forEach((date, index) => {
        const dateKey = date
        mappedResponse.push({
            key: index,
            id: dateKey,
            sectionTitle: dateKey,
            data: mapByDate[date],


        })
    })
    return mappedResponse

}




function mapPageInfo(data) {
    const {
        maxPage,
        currentPage,
        perPage,
    } = data.listInvoicesFromGovBooks

    // Return the rest of the data excluding invoices
    return { maxPage, currentPage, perPage }
}


const mapInvoiceData = (res) => {
    const invoiceData = res?.getInvoiceByFICFromGovBooks?.invoice || {}
    const mappedObj = {
        InvoiceType: invoiceData?.TypeOfInv || '-',
        SelfInvoice: invoiceData?.IsSimplifiedInv || '-', // Assuming the same as IsSimplifiedInv
        SimpleInvoice: invoiceData?.IsSimplifiedInv || '-', // Assuming the same as IsSimplifiedInv
        Date: invoiceData?.IssueDateTime || '-',
        InvoiceNum: invoiceData?.InvNum || '-',
        InvoiceOrderNum: invoiceData?.InvOrdNum || '-',
        TCRCode: invoiceData?.TCRCode || '-',
        IssuerInVAT: invoiceData?.IsIssuerInVAT || '-',
        OperatorCode: invoiceData?.OperatorCode || '-',
        BusinUnitCode: invoiceData?.BusinUnitCode || '-',
        SoftwareCode: invoiceData?.SoftCode || '-',
        IIC: invoiceData?.IIC || '-',
        FIC: invoiceData?.FIC || '-',
        IICSignature: invoiceData?.IIC || '-', // Assuming it's the same as IIC
        IsReverseCharge: invoiceData?.IsReverseCharge || '-',
        PayDeadline: invoiceData?.PayDeadline || '-',
        IsEinvoice: invoiceData?.IsEinvoice || '-',
    }
    return mappedObj
}

const mapTotalInfoData = (response) => {
    const invoice = response?.getInvoiceByFICFromGovBooks?.invoice || {}
    const mappedTotalInfo = {
        TaxFreeAmt: '', // no corresponding key in the response
        MarkUpAmt: '', // no corresponding key in the response
        GoodsExportAmt: '', // no corresponding key in the response
        TotWithoutVAT: invoice?.TotPriceWoVAT || '-',
        TotVATAmt: invoice?.TotVATAmt || '-',
        TotPrice: invoice?.TotPrice || '-',
    }
    return mappedTotalInfo
}


const mapSameTaxes = (response) => {
    const invoice = response?.getInvoiceByFICFromGovBooks?.invoice || {}

    console.log(invoice, 'invoice from map')
    const sameTaxes = invoice?.SameTaxes ? invoice.SameTaxes[0] : null

    if (!sameTaxes) {
        return null
    }

    const mappedTotalInfo = {
        NumberOfItems: sameTaxes?.NumOfItems || '-',
        PriceBeforeVAT: sameTaxes?.PriceBefVAT ? sameTaxes.PriceBefVAT + ' ALL' : '-',
        VATRate: sameTaxes?.VATRate ? sameTaxes.VATRate + ' ALL' : '-',
        VATAmt: sameTaxes?.VATAmt ? sameTaxes.VATAmt + ' ALL' : '-',
    }
    return mappedTotalInfo
}

const mapCorrectiveAndBadDebtInfo = (res) => {
    const invoice = res?.getInvoiceByFICFromGovBooks?.invoice || {}
    const corrective = invoice?.CorrectiveInv
    const mappedObj = {}

    const mappedCorrectiveInvoiceObj = {
        isCorrective: isEmpty(corrective) ? false : true,
        IICRef: corrective?.IICRef || '-', // assuming its IIC
        IssueDate: corrective?.IssueDateTime || '-', // assuming
    }
    const mappedBadDebtInvoiceObj = {
        IICRef: invoice?.IIC || '-', // assuming its IIC
        IssueDate: invoice?.IssueDateTime || '-', // assuming
    }
    mappedObj.corrective = mappedCorrectiveInvoiceObj
    mappedObj.badDebt = mappedBadDebtInvoiceObj
    return mappedObj
}

const mapPaymentInfo = (res) => {
    const invoice = res?.getInvoiceByFICFromGovBooks?.invoice || {}
    const paymentMethods = invoice?.PayMethods || []

    const mappedObj = {
        amount: paymentMethods[0]?.Amt || '-',
        method: paymentMethods[0]?.Type || '-',
    }
    return mappedObj
}







export { GovInvoicesMapper, mapPageInfo, mapInvoiceData, mapTotalInfoData, mapCorrectiveAndBadDebtInfo, mapPaymentInfo, mapSameTaxes }
