/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  memo,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  Animated,
  Easing,
  LayoutAnimation,
  Vibration,
  Platform,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import LottieView from 'lottie-react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { BluetoothManager } from 'react-native-bluetooth-escpos-printer'
import { useTheme } from 'react-native-themed-styles'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { SwipeListView, SwipeRow } from 'react-native-swipe-list-view'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import Sound from 'react-native-sound'
import RNHTMLtoPDF from 'react-native-html-to-pdf'
import Share from 'react-native-share'
import { useReactToPrint } from 'react-to-print'

import {
  Calendar,
  Scan,
  Text,
  Icon,
  DoubleIcon,
  QRScannerOrder,
  Loader,
  Modal,
  Button,
  Header,
  ErrorModal,
  ButtonGroup,
  ItemStatus,
} from '../../Components'
import { printReceipt } from '../../Utils/Order'
import { convertReceipt, convertRefund } from '../../Utils/PdfTemplates'
import { useLanguage } from '../../Utils/Language'
import Merchant from '../../Utils/Merchant'
import { INF, useTemplateLanguage } from '../../Utils'
import { useConfig } from '../../Utils/Config'
import { ConvertRecipt } from '../../Utils/PdfTemplatesWeb'

import { useLocationDetails } from '../../Utils/AuthDetails'

import { GET_QR } from '../../Utils/Utils.Schema'

import Theme, { layoutAnimConfig } from '../../Themes/Theme'
import themedStyles, { itemStyles, hiddenItemStyles } from './Orders.Styles'
import { images, animations } from '../../Themes'
import {
  LIST_TRANSACTIONS_HISTORY,
  GET_TRANSACTION,
  GET_REFUND,
} from './Orders.schema'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const PRINTING_STEPS = {
  data: 'data',
  printing: 'printing',
  success: 'success',
  error: 'error',
}

const LIST_TYPES = {
  list: 'list',
  searchList: 'searchList',
  qrList: 'qrList',
  dateList: 'dateList',
}

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function SectionItem({ sectionTitle }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <Text
      i18nKey={sectionTitle}
      size="footnote"
      color={colors.secondaryText}
      style={styles.sectionTitle}
    />
  )
}
SectionItem.propTypes = {
  sectionTitle: PropTypes.string,
}

const TransactionIcon = memo(() => {
  const [styles] = useTheme(themedStyles)

  return (
    <DoubleIcon
      iconSource={images.cash}
      statusIconSource={images.transaction}
      iconSize={30}
      statusSize={10}
      style={styles.leftSpacing}
      statusIconStyle={styles.bottomSpacing}
    />
  )
})

const RefundIcon = memo(() => {
  const [styles] = useTheme(themedStyles)

  return (
    <DoubleIcon
      iconSource={images.cash}
      statusIconSource={images.refund}
      iconSize={30}
      statusSize={10}
      style={styles.mediumLeftSpacing}
      statusIconStyle={styles.bottomSpacing}
    />
  )
})

const ArrowIcon = memo(() => {
  return <Icon source={images.arrow_right} size={20} />
})

const icons = {
  transaction: TransactionIcon,
  refund: RefundIcon,
}

const Item = memo(
  ({
    isFirst,
    isLast,
    onPress,
    title,
    description,
    subDescText,
    amount,
    type,
    status,
    code,
  }) => {
    const [styles] = useTheme(itemStyles)
    const { colors } = useContext(Theme)
    const TypeIcon = icons[type]
    const ItemStatuses = {
      ACCEPTED: <ItemStatus
        color={colors.green}
        title="invoice_status_success"
      />,
      REJECTED: <ItemStatus
        color={colors.accent}
        title="invoice_status_failed"
        code={code} />,
      IN_PROGRESS: null,
      null: null,
      undefined: null,
    }
    return (
      <View
        style={[
          styles.itemStyle,
          styles[`firstItem_${isFirst}`],
          styles[`lastItem_${isLast}`],
        ]}>
        <TouchableOpacity onPress={onPress} disabled={!onPress}>
          <View style={styles.container}>
            <TypeIcon />
            <View style={styles.listDescContainer}>
              <Text i18nKey={title} numberOfLines={1} size="footnote" />
              <View style={styles.rowView}>
                <Text
                  i18nKey={description}
                  weight="light"
                  color={colors.secondaryText}
                  numberOfLines={1}
                  style={styles.description(type)}
                />
                {ItemStatuses[status]}
              </View>
              <Text
                i18nKey={subDescText}
                numberOfLines={1}
                weight="light"
                color={colors.secondaryText}
                style={styles.subDescription}
              />
            </View>
            <View style={styles.amountContainer}>
              <Text i18nKey={amount} style={styles.rightSpacing} />
              <ArrowIcon />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    )
  },
)
Item.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onPress: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  subDescText: PropTypes.string,
  amount: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  code: PropTypes.string,
}

function ErrorItem({ search, loading }) {
  const [styles] = useTheme(itemStyles)

  return (
    <View style={styles.errorContainer(search)}>
      <View style={styles.errorSubcontainer}>
        {search ? (
          <>
            <Icon source={images.not_found} size={35} />
            <View style={styles.horizontalSpacing}>
              <Text i18nKey="orders_not_found_title" align="center" />
              <Text
                i18nKey="orders_not_found_description"
                align="center"
                size="footnote"
                weight="light"
              />
            </View>
          </>
        ) : loading ? null : (
          <>
            <Text
              i18nKey="orders_no_transactions_label"
              align="center"
              size="body"
              weight="light"
            />
          </>
        )}
      </View>
    </View>
  )
}
ErrorItem.propTypes = {
  search: PropTypes.bool,
  loading: PropTypes.bool,
}

const HiddenItem = memo(({ isFirst, isLast, onPrintPress, onSharePress, shareLoading }) => {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View
      style={[
        styles.hiddenItemStyle,
        styles[`firstHiddenItem_${isFirst}`],
        styles[`lastHiddenItem_${isLast}`],
      ]}>
      <TouchableOpacity
        style={styles.hiddenSubcontainer}
        onPress={onPrintPress}>
        <Icon source={images.printerActive} color={colors.white} size={24} />
      </TouchableOpacity>
      {Platform.OS !== 'web' && <><View style={styles.divider} />
        <TouchableOpacity
          style={styles.hiddenSubcontainer}
          onPress={onSharePress}
          disabled={shareLoading}
        >
          {shareLoading ? <Loader source={animations.vfLoaderWhite} size={24} /> : <Icon source={images.share} color={colors.white} size={24} />}
        </TouchableOpacity></>}
    </View>
  )
})
HiddenItem.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onPrintPress: PropTypes.func,
  onSharePress: PropTypes.func,
  shareLoading: PropTypes.bool,
}

function EmptyItem() {
  return null
}

const listItems = {
  section: SectionItem,
  transaction: Item,
  refund: Item,
}

const hiddenItems = {
  section: EmptyItem,
  transaction: HiddenItem,
  refund: HiddenItem,
}

function TransactionsList({
  list,
  items,
  hasHeader,
  selected,
  setSelected,
  fetchMore,
  onPrintPress,
  onSharePress,
  sectionDate,
  value,
  selectedDay,
  barcodeValue,
  navigate,
  setError,
  setListType,
  refetch,
  previewIndex,
  shareLoading,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()



  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const [data, setData] = useState([])
  const [qrList, setQrList] = useState(false)
  const [search, setSearch] = useState(false)
  const [loading1, setLoading] = useState(true)



  useEffect(() => {
    setLoading(true)
    setData([])
    list()
  }, [selected])


  useEffect(() => {
    setLoading(true)
    setListType(LIST_TYPES.list)
    sectionDate.current = null
    list()
  }, [])

  useEffect(() => {
    if (value) {
      setListType(LIST_TYPES.searchList)
      setLoading(true)
      sectionDate.current = null
      list()
    }
  }, [value])

  useEffect(() => {
    if (selectedDay) {
      setListType(LIST_TYPES.dateList)
      sectionDate.current = null
      list()
    }
  }, [selectedDay])

  useEffect(() => {
    if (barcodeValue) {
      sectionDate.current = null
      setSearch(false)
      setLoading(true)
      setListType(LIST_TYPES.qrList)
      setQrList(true)
      list()
    }
  }, [barcodeValue])

  const mapData = (dataa) => {
    const response = []
    let itemTime, itemType, status
    let isFirst = false,
      isLast = false
    sectionDate.current = null
    dataa?.map((item, index) => {
      isFirst = false
      isLast = false
      itemType = item.type.toLowerCase()
      itemTime = item[itemType].createdAt
      const date = moment(itemTime)
        .locale(LANGUAGES[lang])
        .format('MMM DD, YYYY')
      if (date !== sectionDate.current) {
        if (response?.[response.length - 1]) {
          response[response.length - 1].isLast = true
        }
        sectionDate.current = date
        response.push({
          id: date,
          sectionTitle: date,
          type: 'section',
        })
      }

      if (response?.[response.length - 1]?.type === 'section') {
        isFirst = true
        isLast = false
      }

      const element = item[itemType]
      let amount, currency, total, title, code, onItemPress, transactionId
      if (itemType === 'transaction') {
        code = element?.code
        status = element?.status
        title = element?.receiptNumber
        const amountMoney = element?.tender?.additionalAmountMoney || element?.tender?.amountMoney
        amount = INF.format(amountMoney?.amount)
        currency = amountMoney?.currency
        total = `+${amount} ${currency}`
        transactionId = element.id
        onItemPress = () =>
          navigate('Receipt', {
            id: element.id,
            receiptNumber: title,
          })
      } else {
        code = element?.code
        status = element?.status
        title = element.receiptNumber
        amount = INF.format(element?.amountMoney?.amount)
        currency = element?.amountMoney?.currency
        total = `-${amount} ${currency}`
        transactionId = element.transactionId
        onItemPress = () =>
          navigate('Refund', {
            id: element.transactionId,
            refundId: element.id,
            type: 'goBack',
          })
      }
      response.push({
        id: element.id,
        transactionId: transactionId,
        type: itemType,
        title: title,
        description: moment(itemTime).locale(LANGUAGES[lang]).format('hh:mm A'),
        subDescText: element.employee,
        amount: total,
        isFirst: isFirst,
        status: status,
        code: code,
        isLast: isLast,
        onPress: onItemPress,
      })
    })
    if (response?.[response.length - 1]) {
      response[response.length - 1].isLast = true
    }
    return response
  }

  useEffect(() => {
    if (items) {
      const dataa = items?.transactionsHistory
      const resp = mapData(dataa?.items)
      if (qrList) {
        if (dataa?.items.length > 0) {
          setError(false)
        } else {
          setError(true)
          setSearch(true)
        }
      }
      setData(resp)
      setLoading(false)
    }
  }, [items])

  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = (val) => {
    Animated.timing(fabSize, {
      toValue: val,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }
  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }
  const keyExtractor = useCallback((item, index) => 'row-' + index, [])
  const getItemLayout = useCallback(
    (_, index) => ({
      length: 68,
      offset: 68 * index,
      index,
    }),
    [],
  )
  const onEndReached = () => {
    if (items?.transactionsHistory?.nextToken === null) {
      return
    }
    setLoading(true)
    let beginTime = '',
      endTime = ''
    if (selectedDay) {
      beginTime = moment(selectedDay)
        .tz('Europe/Tirane')
        .locale(LANGUAGES[lang])
        .format('YYYY-MM-DD')
      endTime = moment(selectedDay)
        .add(1, 'days')
        .tz('Europe/Tirane')
        .locale(LANGUAGES[lang])
        .format('YYYY-MM-DD')
    }
    if (value) {
      fetchMore({
        variables: {
          limit: 15,
          nextToken: items?.transactionsHistory?.nextToken,
          locationId: locationId,
          cashRegisterId: deviceId,
          name: 'receipt',
          value: value,
        },
      })
    } else {
      fetchMore({
        variables: {
          limit: 15,
          nextToken: items?.transactionsHistory?.nextToken,
          locationId: locationId,
          cashRegisterId: deviceId,
          beginTime: beginTime,
          endTime: endTime,
        },
      })
    }
  }

  const refetchData = async () => {
    if (refetch) {
      await refetch()
    }
  }

  useFocusEffect(
    useCallback(() => {
      refetchData()
    }, [])
  )

  const types = [
    {
      title: 'users_all_filter',
      type: 'ALL',
    },
    {
      title: 'payment_label_type_cash',
      type: 'CASH',
    },
    {
      title: 'payment_title_type_noncash',
      type: 'NONCASH',
    },
  ]

  return (
    <>
      <SwipeListView
        data={data}
        useFlatList
        listViewRef={(ref) => {
          listRef.current = ref
        }}
        useAnimatedList
        onMomentumScrollEnd={(event) => {
          if (event.nativeEvent.contentOffset.y > 800) {
            animate(1)
          } else {
            animate(0)
          }
        }}
        // onLayout={(event) => measureView(event)}
        keyExtractor={keyExtractor}
        style={styles.listStyle}
        contentContainerStyle={styles.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        getItemLayout={getItemLayout}
        closeOnRowBeginSwipe={true}
        closeOnRowOpen={true}
        closeOnScroll={true}
        ListHeaderComponent={hasHeader && <ButtonGroup
          selected={selected}
          onPress={setSelected}
          options={types}
          style={styles.headerStyles}
        />}
        ListEmptyComponent={<ErrorItem search={search} loading={loading1} />}
        ListFooterComponent={
          <View style={styles.footer}>
            {loading1 ? <Loader size={32} /> : null}
          </View>
        }
        renderItem={(rowData, rowMap) => {
          const { item, index } = rowData
          const ListItem = listItems[item.type]
          const ListHiddenItem = hiddenItems[item.type]

          return (
            <SwipeRow
              key={'row-' + index}
              disableRightSwipe
              disableLeftSwipe={item.type === 'section' || Platform.OS === 'web' ? true : false}
              rightOpenValue={Platform.OS === 'web' ? -81 : -161}
              closeOnRowPress
              recalculateHiddenLayout={false}
              useNativeDriver={true}
              preview={index === 1 && previewIndex < 4}
              previewDuration={370}
              previewOpenValue={-161}>
              <ListHiddenItem
                {...item}
                key={'row-' + index}
                shareLoading={shareLoading}
                onPrintPress={() =>
                  onPrintPress(item.id, item.type, item.transactionId)
                }
                onSharePress={() => onSharePress(item.id, item.type, item.transactionId)}
              />
              <ListItem
                {...item}
                sectionTitle={item.sectionTitle}
                key={'row-' + index}
              />
            </SwipeRow>
          )
        }}
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        onRefresh={() => refetch()}
        refreshing={false}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable>
    </>
  )
}
TransactionsList.propTypes = {
  list: PropTypes.func,
  items: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  fetchMore: PropTypes.func,
  onPrintPress: PropTypes.func,
  onSharePress: PropTypes.func,
  sectionDate: PropTypes.object,
  value: PropTypes.string,
  selectedDay: PropTypes.string,
  barcodeValue: PropTypes.string,
  navigate: PropTypes.func,
  setError: PropTypes.func,
  listType: PropTypes.string,
  setListType: PropTypes.func,
  refetch: PropTypes.func,
  previewIndex: PropTypes.number,
  shareLoading: PropTypes.bool,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  hasHeader: PropTypes.bool,
}

function List({
  selected,
  setSelected,
  sectionDate,
  onPrintPress,
  onSharePress,
  navigate,
  listType,
  setListType,
  previewIndex,
  shareLoading,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()


  const [
    listTransactionHistory,
    {
      loading: listLoading,
      error: listError,
      data: listData,
      refetch,
      fetchMore,
    },
  ] = useLazyQuery(LIST_TRANSACTIONS_HISTORY, {
    fetchPolicy: 'network-only',
  })

  const list = () => {
    if (selected === 'ALL') {
      listTransactionHistory({
        variables: {
          locationId: locationId,
          cashRegisterId: deviceId,
          limit: 25,
          sortOrder: 'DESC',

        },
      })
    } else {
      listTransactionHistory({
        variables: {
          locationId: locationId,
          cashRegisterId: deviceId,
          limit: 25,
          sortOrder: 'DESC',
          name: 'orderType',
          value: selected,
        },
      })
    }
  }

  return (
    <TransactionsList
      hasHeader={true}
      list={list}
      loading={listLoading}
      error={listError}
      items={listData}
      onPrintPress={onPrintPress}
      onSharePress={onSharePress}
      sectionDate={sectionDate}
      navigate={navigate}
      fetchMore={fetchMore}
      refetch={refetch}
      listType={listType}
      setListType={setListType}
      previewIndex={previewIndex}
      shareLoading={shareLoading}
      selected={selected}
      setSelected={setSelected}
    />
  )
}
List.propTypes = {
  sectionDate: PropTypes.object,
  onPrintPress: PropTypes.func,
  onSharePress: PropTypes.func,
  navigate: PropTypes.func,
  listType: PropTypes.string,
  setListType: PropTypes.func,
  previewIndex: PropTypes.number,
  shareLoading: PropTypes.bool,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
}

function SearchList({
  value,
  sectionDate,
  onPrintPress,
  onSharePress,
  navigate,
  setListType,
  shareLoading,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [
    listTransactionHistory,
    {
      loading: listLoading,
      error: listError,
      data: listData,
      refetch,
      fetchMore,
    },
  ] = useLazyQuery(LIST_TRANSACTIONS_HISTORY, {
    fetchPolicy: 'network-only',
  })

  const transactionSearchList = () => {
    listTransactionHistory({
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
        name: 'receipt',
        value: value,
        limit: 15,
        sortOrder: 'DESC',
      },
    })
  }

  return (
    <TransactionsList
      hasHeader={false}
      list={transactionSearchList}
      loading={listLoading}
      error={listError}
      items={listData}
      value={value}
      onPrintPress={onPrintPress}
      onSharePress={onSharePress}
      navigate={navigate}
      sectionDate={sectionDate}
      fetchMore={fetchMore}
      refetch={refetch}
      setListType={setListType}
      shareLoading={shareLoading}
    />
  )
}
SearchList.propTypes = {
  sectionDate: PropTypes.object,
  onPrintPress: PropTypes.func,
  onSharePress: PropTypes.func,
  navigate: PropTypes.func,
  value: PropTypes.string,
  setListType: PropTypes.func,
  shareLoading: PropTypes.bool,
}

function SearchDateList(props) {
  const {
    selectedDay,
    sectionDate,
    onPrintPress,
    onSharePress,
    navigate,
    setListType,
    shareLoading,
    selected,
    setSelected,
  } = props

  const lang = useLanguage()
  const { switchLocation: { deviceId, locationId } } = useConfig()


  const [
    listTransactionHistory,
    {
      loading: listLoading,
      error: listError,
      data: listData,
      refetch,
      fetchMore,
    },
  ] = useLazyQuery(LIST_TRANSACTIONS_HISTORY, {
    fetchPolicy: 'network-only',
  })

  const listWithDate = () => {
    const endDate = moment(selectedDay)
      .add(1, 'days')
      .tz('Europe/Tirane')
      .locale(LANGUAGES[lang])
      .format('YYYY-MM-DD')
    if (selected === 'ALL') {
      listTransactionHistory({
        variables: {
          locationId: locationId,
          cashRegisterId: deviceId,
          limit: 15,
          beginTime: moment(selectedDay)
            .tz('Europe/Tirane')
            .locale(LANGUAGES[lang])
            .format('YYYY-MM-DD'),
          endTime: endDate,
          sortOrder: 'DESC',
        },
      })
    } else {
      listTransactionHistory({
        variables: {
          locationId: locationId,
          cashRegisterId: deviceId,
          limit: 15,
          beginTime: moment(selectedDay)
            .tz('Europe/Tirane')
            .locale(LANGUAGES[lang])
            .format('YYYY-MM-DD'),
          endTime: endDate,
          sortOrder: 'DESC',
          name: 'orderType',
          value: selected,
        },
      })
    }

  }

  return (
    <TransactionsList
      hasHeader={true}
      list={listWithDate}
      loading={listLoading}
      error={listError}
      items={listData}
      onPrintPress={onPrintPress}
      onSharePress={onSharePress}
      selectedDay={selectedDay}
      navigate={navigate}
      setListType={setListType}
      sectionDate={sectionDate}
      fetchMore={fetchMore}
      refetch={refetch}
      shareLoading={shareLoading}
      selected={selected}
      setSelected={setSelected}
    />
  )
}
SearchDateList.propTypes = {
  sectionDate: PropTypes.object,
  onPrintPress: PropTypes.func,
  onSharePress: PropTypes.func,
  navigate: PropTypes.func,
  selectedDay: PropTypes.string,
  setListType: PropTypes.func,
  shareLoading: PropTypes.bool,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
}

function QRList({
  barcodeValue,
  sectionDate,
  onPrintPress,
  onSharePress,
  navigate,
  setError,
  setListType,
  shareLoading,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [
    listTransactionHistory,
    {
      loading: listLoading,
      error: listError,
      data: listData,
      refetch,
      fetchMore,
    },
  ] = useLazyQuery(LIST_TRANSACTIONS_HISTORY, {
    fetchPolicy: 'network-only',
  })

  const transactionList = () => {
    listTransactionHistory({
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
        name: 'qrCode',
        value: barcodeValue,
      },
    })
  }

  return (
    <TransactionsList
      hasHeader={false}
      list={transactionList}
      loading={listLoading}
      error={listError}
      items={listData}
      onPrintPress={onPrintPress}
      onSharePress={onSharePress}
      navigate={navigate}
      barcodeValue={barcodeValue}
      sectionDate={sectionDate}
      fetchMore={fetchMore}
      setError={setError}
      setListType={setListType}
      refetch={refetch}
      shareLoading={shareLoading}
    />
  )
}
QRList.propTypes = {
  sectionDate: PropTypes.object,
  onPrintPress: PropTypes.func,
  onSharePress: PropTypes.func,
  navigate: PropTypes.func,
  barcodeValue: PropTypes.string,
  setListType: PropTypes.func,
  setError: PropTypes.func,
  shareLoading: PropTypes.bool,
}

function Data() {
  const [styles] = useTheme(themedStyles)
  const anim = useRef()

  return (
    <View style={styles.lottieWrapper}>
      <LottieView
        ref={(ref) => {
          anim.current = ref
        }}
        source={animations.print}
        autoPlay
        loop={false}
        resizeMode="cover"
        style={styles.lottieView}
      />
      <Text i18nKey="orders_getting_receipt_label" size="h3" />
    </View>
  )
}

function Printing({
  data,
  cash,
  setPrintingStatus,
  setOpened,
  customer,
  otherCurrency,
}) {
  const [styles] = useTheme(themedStyles)
  const context = useContext(Merchant)
  const { euRate, usdRate, poundRate } = useLocationDetails()
  const { translate } = useTemplateLanguage()
  const anim = useRef()
  const convertReceiptWebRef = useRef()

  const OTHER_CURRENCY_DETAILS = {
    EUR: {
      currency: 'EUR',
      rate: euRate,
    },
    USD: {
      currency: 'USD',
      rate: usdRate,
    },
    GBP: {
      currency: 'GBP',
      rate: poundRate,
    },
  }

  const handlePrintWeb = useReactToPrint({
    content: () => convertReceiptWebRef.current,
  })

  const print = (transaction, cashMoney) => {
    anim.current?.play()
    if (Platform.OS === 'web') {
      handlePrintWeb()
      setPrintingStatus(PRINTING_STEPS.success)
    } else {
      printReceipt(
        transaction,
        cashMoney,
        OTHER_CURRENCY_DETAILS?.[otherCurrency]?.rate,
        OTHER_CURRENCY_DETAILS?.[otherCurrency]?.currency,
        context?.nipt,
        context?.company,
        context?.location,
        customer,
        translate
      )
        .then(() => {
          setPrintingStatus(PRINTING_STEPS.success)
        })
        .catch((e) => {
          setOpened(true)
        })
    }
  }

  useEffect(() => {
    if (data && cash) {
      print(data, cash)
    }
  }, [data, cash])

  return (
    <>
      <View style={styles.lottieWrapper}>
        <LottieView
          ref={(ref) => {
            anim.current = ref
          }}
          source={animations.print}
          autoPlay
          loop={false}
          resizeMode="cover"
          style={styles.lottieView}
        />
        <Text i18nKey="orders_printing_label" size="h3" />
      </View>
      {Platform.OS === 'web' && <div style={styles.hidden}>
        <ConvertRecipt
          transaction={data}
          cashBuyer={cash}
          change={data?.tender?.cashDetails?.changeBackMoney?.amount}
          nipt={context?.nipt}
          company={context?.company}
          location={context?.location}
          customer={customer}
          ref={convertReceiptWebRef}
        />
      </div>
      }
    </>
  )
}
Printing.propTypes = {
  data: PropTypes.object,
  cash: PropTypes.string,
  setPrintingStatus: PropTypes.func,
  setOpened: PropTypes.func,
  customer: PropTypes.object,
  otherCurrency: PropTypes.string,
}

function Success({ setModalVisible }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  return (
    <View style={styles.successWrapper}>
      <View
        style={styles.lottieWrapper}>
        <LottieView
          source={animations.success}
          autoPlay
          loop={false}
          resizeMode="cover"
          style={styles.lottie}
        />
        <Text i18nKey="orders_success_label" size="h3" align="center" />
      </View>
      <Button
        title="orders_button_go_back"
        backgroundColor={colors.accent}
        borderColor={colors.transparent}
        style={styles.backButton}
        onPress={() => setModalVisible(false)}
      />
    </View>
  )
}
Success.propTypes = {
  setModalVisible: PropTypes.func,
}

const PrintingSteps = {
  data: Data,
  printing: Printing,
  success: Success,
}

const ListTypes = {
  list: List,
  searchList: SearchList,
  qrList: QRList,
  dateList: SearchDateList,
}

function ModalContent({
  id,
  type: transactionType,
  transactionId,
  setModalVisible,
  navigate,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [customerData, setCustomer] = useState({})
  const [printingStatus, setPrintingStatus] = useState(PRINTING_STEPS.data)
  const PrintingStep = PrintingSteps[printingStatus]

  const [orderData, serOrderData] = useState({})
  const [cash, setCash] = useState('')
  const [otherCurrency, setOtherCurrency] = useState({})
  const [opened, setOpened] = useState(false)

  const [getTransactionDetails, { data }] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: 'network-only',
  })

  const getTransaction = () => {
    getTransactionDetails({
      variables: {
        locationId: 'K11715005L',
        locId: locationId,
        cashRegisterId: deviceId,
        transactionId: id,
      },
    })
  }

  const [
    getRefund,
    { data: refundData },
  ] = useLazyQuery(GET_REFUND, {
    fetchPolicy: 'network-only',
  })

  const getRefundData = () => {
    getRefund({
      variables: {
        locationId: 'K11715005L',
        locId: locationId,
        cashRegisterId: deviceId,
        transactionId: transactionId,
        refundId: id,
      },
    })
  }

  useEffect(() => {
    if (transactionType === 'transaction') {
      getTransaction()
    } else {
      getRefundData()
    }
  }, [])

  useEffect(() => {
    if (data) {
      const transaction = data?.getTransaction
      const customer1 = transaction?.customer
      let customer = {}
      if (customer1) {
        const {
          id: customerId,
          issuerNUIS,
          businessName,
          givenName,
          familyName,
          reference,
          address: {
            addressLine1,
            country,
            administrativeDistrictLevel1,
          },
          type,
        } = customer1
        customer = {
          customerId,
          address: `${addressLine1}, ${administrativeDistrictLevel1}, ${country}`,
        }
        if (type === 'INDIVIDUAL') {
          const { name: idName, value: idValue } = reference || {}
          customer = { ...customer, title: givenName + ' ' + familyName, idName, idValue }
        } else {
          customer = { ...customer, title: businessName, nipt: issuerNUIS }
        }
      }
      setCustomer(customer)
      serOrderData(transaction)
      setCash(`${transaction?.tender?.cashDetails?.buyerTenderedMoney?.amount}`)
      setOtherCurrency(transaction?.tender?.additionalAmountMoney || transaction?.tender?.amountMoney)
      setPrintingStatus(PRINTING_STEPS.printing)
    }
  }, [data])

  useEffect(() => {
    if (refundData) {
      const refund = refundData.getRefund
      const customer1 = refund?.customer
      let customer = {}
      if (customer1) {
        const {
          id: customerId,
          issuerNUIS,
          businessName,
          givenName,
          familyName,
          reference,
          address: {
            addressLine1,
            country,
            administrativeDistrictLevel1,
          },
          type,
        } = customer1
        customer = {
          customerId,
          address: `${addressLine1}, ${administrativeDistrictLevel1}, ${country}`,
        }
        if (type === 'INDIVIDUAL') {
          const { name: idName, value: idValue } = reference || {}
          customer = { ...customer, title: givenName + ' ' + familyName, idName, idValue }
        } else {
          customer = { ...customer, title: businessName, nipt: issuerNUIS }
        }
      }
      setCustomer(customer)
      serOrderData(refund)
      setCash(`${refund?.amountMoney?.amount}`)
      setPrintingStatus(PRINTING_STEPS.printing)
    }
  }, [refundData])

  return (
    <><PrintingStep
      data={orderData}
      cash={cash}
      setPrintingStatus={setPrintingStatus}
      setOpened={setOpened}
      setModalVisible={setModalVisible}
      navigate={navigate}
      customer={customerData}
      otherCurrency={otherCurrency?.currency}
    />
      <ErrorModal
        isVisible={opened}
        icon={images.error}
        title="orders_error_label"
        description="orders_printing_interrupted_label"
        primaryText="receipt_open_printers_button"
        primaryAction={() => {
          setOpened(false)
          setModalVisible(false)
          navigate('PrinterSetUp')
        }}
        secondaryText="checkout_button_skip"
        secondaryAction={() => {
          setOpened(false)
          setModalVisible(false)
        }}
        onBackdropPress={() => {
          setOpened(false)
          setModalVisible(false)
        }}
        onClosePress={() => {
          setOpened(false)
          setModalVisible(false)
        }}
      />
    </>
  )
}
ModalContent.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  transactionId: PropTypes.string,
  setModalVisible: PropTypes.func,
  navigate: PropTypes.func,
}

function Orders({
  navigation: {
    goBack,
    navigate,
    openDrawer,
    addListener,
  },
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const context = useContext(Merchant)
  const { openConfigurationSelector, translate } = useTemplateLanguage()
  const {
    soundEnabled,
    vibrationEnabled,
  } = useConfig()
  // Camera visibility
  const [isVisible, setVisible] = useState(false)
  const client = useApolloClient()

  // Calendar
  const [selectedDay, setSelectedDay] = useState()

  //screen data
  const [barcodeValue, setBarcodeValue] = useState('')
  const [isError, setError] = useState(false)

  //filters
  const [selected, setSelected] = useState('ALL')

  const [value, setValue] = useState('')
  const sectionDate = useRef('-')
  const [isModalVisible, setModalVisible] = useState(false)
  const [previewIndex, setPreviewIndex] = useState(1)
  const lastScanned = useRef(null)

  const [listType, setListType] = useState(LIST_TYPES.list)
  console.log('🚀 ~ listType:', listType)
  const ListType = ListTypes[listType]

  const [contentData, setData] = useState({
    id: '',
    type: '',
    transactionId: '',
  })

  const [shareLoading, setShareLoading] = useState(false)

  const getData = async () => {
    let val
    try {
      val = (await AsyncStorage.getItem('selectedPrinter')) || ''
      if (val) {
        await BluetoothManager.connect(val)
          .then(() => { })
          .catch(() => { })
      } else {
        val = ''
      }
    } catch (e) {
      val = ''
    }
  }

  const storePreviewIndex = async (val) => {
    setPreviewIndex(val)
    try {
      await AsyncStorage.setItem('preview', `${val}`)
    } catch (e) { }
  }

  const getPreviewIndex = async () => {
    await AsyncStorage.getItem('preview')
      .then((response) => {
        const resNum = parseInt(response, 10) + 1 || 1
        storePreviewIndex(resNum)
      })
      .catch(() => { })
  }

  useEffect(() => {
    getData()
  }, [])

  const qrImage = {
    true: images.closeIcon,
    false: images.qrcode,
  }

  const [getTransactionDetails, { data: transactionData }] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: 'network-only',
  })

  const getTransaction = (id) => {
    getTransactionDetails({
      variables: {
        locationId: 'K11715005L',
        locId: locationId,
        cashRegisterId: deviceId,
        transactionId: id,
      },
    })
  }

  const [
    getRefund,
    { data: refundData },
  ] = useLazyQuery(GET_REFUND, {
    fetchPolicy: 'network-only',
  })

  const getRefundData = (id, transactionId) => {
    getRefund({
      variables: {
        locationId: 'K11715005L',
        locId: locationId,
        cashRegisterId: deviceId,
        transactionId: transactionId,
        refundId: id,
      },
    })
  }

  const onPrintPress = (id, type, transactionId) => {
    openConfigurationSelector(false, () => {
      setData({
        id: id,
        type: type,
        transactionId: transactionId,
      })
      setModalVisible(true)
    })
  }

  const onSharePress = (id, type, transactionId) => {
    setShareLoading(true)
    if (type === 'transaction') {
      getTransaction(id)
    } else if (type === 'refund') {
      getRefundData(id, transactionId)
    }
  }

  const createTransactionPdf = async () => {
    const transaction = transactionData?.getTransaction
    const customer1 = transaction?.customer
    let customer = {}
    if (customer1) {
      const {
        id: customerId,
        issuerNUIS,
        businessName,
        givenName,
        familyName,
        reference,
        address: {
          addressLine1,
          country,
          administrativeDistrictLevel1,
        },
        type,
      } = customer1
      customer = {
        customerId,
        address: `${addressLine1}, ${administrativeDistrictLevel1}, ${country}`,
      }
      if (type === 'INDIVIDUAL') {
        const { name: idName, value: idValue } = reference || {}
        customer = { ...customer, title: givenName + ' ' + familyName, idName, idValue }
      } else {
        customer = { ...customer, title: businessName, nipt: issuerNUIS }
      }
    }
    const change = transaction?.tender?.cashDetails?.changeBackMoney?.amount
    const totalMoney = transaction?.tender?.cashDetails?.buyerTenderedMoney?.amount
    const { data: qr } = await client.query({
      query: GET_QR,
      variables: {
        text: transaction.qrCode,
      },
    })

    openConfigurationSelector(true, () => {
      RNHTMLtoPDF.convert({
        html: convertReceipt(
          transaction,
          totalMoney,
          change,
          context?.nipt,
          context?.company, context?.location,
          customer,
          translate,
          qr,
        ),
        fileName: 'fature',
      })
        .then((res) => {
          Share.open({
            url: `file://${res.filePath}`,
            subject: 'Fatura ' + transaction?.receiptNumber,
            title: 'Fatura',
          })
            .catch(() => { })
          setShareLoading(false)
        })
        .catch(() => { })
    })
    setShareLoading(false)
  }
  useEffect(() => {
    if (transactionData) {
      createTransactionPdf().catch(e => { })
    }
  }, [transactionData])

  const createRefundPdf = async () => {

    const refund = refundData?.getRefund
    const customer1 = refund?.customer
    let customer = {}
    if (customer1) {
      const {
        id: customerId,
        issuerNUIS,
        businessName,
        givenName,
        familyName,
        reference,
        address: {
          addressLine1,
          country,
          administrativeDistrictLevel1,
        },
        type,
      } = customer1
      customer = {
        customerId,
        address: `${addressLine1}, ${administrativeDistrictLevel1}, ${country}`,
      }
      if (type === 'INDIVIDUAL') {
        const { name: idName, value: idValue } = reference || {}

        customer = { ...customer, title: givenName + ' ' + familyName, idName, idValue }
      } else {
        customer = { ...customer, title: businessName, nipt: issuerNUIS }
      }
    }
    const { data: qr } = await client.query({
      query: GET_QR,
      variables: {
        text: refund.qrCode,
      },
    })

    openConfigurationSelector(true, () => {
      RNHTMLtoPDF.convert({
        html: convertRefund(
          refund,
          refund?.amountMoney?.amount,
          context?.nipt,
          context?.company,
          context?.location,
          customer,
          translate,
          qr
        ),
        fileName: 'fature_korrigjuese',
      })
        .then((res) => {
          Share.open({
            url: `file://${res.filePath}`,
            subject: 'Fatura korrigjuese ' + refund?.receiptNumber,
            title: 'Fatura korrigjuese',
          })
            .catch(() => { })
          setShareLoading(false)
        })
        .catch(() => { })
    })
    setShareLoading(false)

  }
  useEffect(() => {
    if (refundData) {
      createRefundPdf().catch(e => { })
    }
  }, [refundData])

  const handleDaySelection = (day) => {
    setSelectedDay(day)
    setListType(LIST_TYPES.dateList)
  }

  useFocusEffect(
    useCallback(() => {
      getPreviewIndex()
      setVisible(false)
      // setListType(LIST_TYPES.searchList)
      setBarcodeValue('')
      lastScanned.current = null
      // setValue('')
      // setSelectedDay('')
    }, []),
  )

  const toggleCamera = () => {
    if (!isVisible) {
      setError(false)
    } else {
      setListType(LIST_TYPES.list)
      setBarcodeValue('')
      lastScanned.current = null
    }
    LayoutAnimation.configureNext(layoutAnimConfig)
    setVisible((prev) => !prev)
  }

  const onBarcodeRead = (event) => {
    const barcode = event.data
    if (barcode !== lastScanned.current) {
      if (soundEnabled) {
        const whoosh = new Sound('scan.mp3', Sound.MAIN_BUNDLE, (error) => {
          if (error) {
            return
          }
          whoosh?.play(() => {
            whoosh.release()
          })
        })
      }
      if (vibrationEnabled) {
        Vibration.vibrate([0, 200])
      }
      lastScanned.current = barcodeValue
      setBarcodeValue(barcode)
      setListType(LIST_TYPES.qrList)
    }
  }

  useEffect(() => {
    if (value === '') {
      setListType(LIST_TYPES.list)
    } else {
      setListType(LIST_TYPES.searchList)
    }
  }, [value])

  useEffect(() => {
    if (barcodeValue === '') {
      setListType(LIST_TYPES.list)
    } else {
      setListType(LIST_TYPES.qrList)
    }
  }, [barcodeValue])

  const onSearchClean = () => {
    setValue('')
  }
  const memoList = useMemo(
    () => (
      <ListType
        selected={selected}
        setSelected={setSelected}
        value={value}
        sectionDate={sectionDate}
        selectedDay={selectedDay}
        navigate={navigate}
        barcodeValue={barcodeValue}
        onPrintPress={onPrintPress}
        onSharePress={onSharePress}
        setError={setError}
        listType={listType}
        setListType={setListType}
        previewIndex={previewIndex}
        shareLoading={shareLoading}
      />
    ),
    [listType, value, selectedDay, barcodeValue, previewIndex, sectionDate, shareLoading, selected],
  )
  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_transactions"
        image={images.back}
        onPress={() => goBack()}
      />
      <View style={styles.qrCameraContainer(isVisible)}>
        <QRScannerOrder
          error={isError}
          qr
          retry={() => {
            setError(false)
          }}
          onBarcodeRead={onBarcodeRead}
          visible={isVisible}
        />
      </View>
      {memoList}
      <Modal
        isVisible={isModalVisible}
        onBackButtonPress={() => setModalVisible(false)}
        animationIn="fadeIn"
        animationOut="fadeOut"
        useNativeDriver
        style={styles.modal}>
        <View style={styles.modalContent}>
          <ModalContent
            id={contentData.id}
            type={contentData.type}
            transactionId={contentData.transactionId}
            isModalVisible={isModalVisible}
            setModalVisible={setModalVisible}
            navigate={navigate}
          />
        </View>
      </Modal>
      <View style={styles.searchContainer}>
        <Calendar
          selectedDay={selectedDay}
          placeholder="orders_calendar_placeholder"
          onDayPress={handleDaySelection}
          withInput
          value={value}
          onChangeText={setValue}
          onSearchClean={() => onSearchClean()}
          removeSelectedDate={() => {
            setListType(LIST_TYPES.list)
            setSelectedDay('')
          }}
        />
        {Platform.OS !== 'web' && <Scan onPress={toggleCamera} image={qrImage[`${isVisible}`]} />}
      </View>
    </SafeAreaView>
  )
}
Orders.propTypes = {
  navigation: PropTypes.object,
}

export default Orders
