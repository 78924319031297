
import React, { useContext, useEffect } from 'react'
import { ScrollView, KeyboardAvoidingView, Platform } from 'react-native'
import { Authenticator as Auth, AmplifyTheme } from 'aws-amplify-react-native'
import Amplify, { I18n } from '@aws-amplify/core'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import { amplifyConfig } from '../../Config'
import { dict } from '../../Utils/Dict'
import { useLanguage } from '../../Utils/Language'

import Theme from '../../Themes/Theme'
import themedStyles from './Authenticator.styles'

I18n.putVocabularies(dict)
I18n.setLanguage('al')
Amplify.configure(amplifyConfig)

function Authenticator({
  onSuccess,
}) {
  const { colors } = useContext(Theme)
  const [styles] = useTheme(themedStyles)
  const lang = useLanguage()

  useEffect(() => {
    I18n.setLanguage(lang)
  }, [lang])

  const signUpConfig = {
    hideAllDefaults: true,
    defaultCountryCode: '355',
    signUpFields: [
      {
        label: I18n.get('Name'),
        placeholder: I18n.get('Enter your name'),
        key: 'name',
        required: true,
        displayOrder: 1,
        type: 'string',
      },
      {
        label: I18n.get('Family Name'),
        placeholder: I18n.get('Enter your family name'),
        key: 'family_name',
        required: true,
        displayOrder: 2,
        type: 'string',
      },
      {
        label: I18n.get('Username'),
        placeholder: I18n.get('Enter your username'),
        key: 'username',
        required: true,
        displayOrder: 3,
        type: 'string',
      },
      {
        label: I18n.get('Phone Number'),
        placeholder: I18n.get('Enter your phone number'),
        key: 'phone_number',
        required: true,
        displayOrder: 4,
        type: 'string',
      },
      {
        label: I18n.get('Email'),
        placeholder: I18n.get('Enter your email'),
        key: 'email',
        required: true,
        displayOrder: 5,
        type: 'email',
      },
      {
        label: I18n.get('Password'),
        placeholder: I18n.get('Enter your password'),
        key: 'password',
        required: true,
        displayOrder: 6,
        type: 'password',
      },
    ],
  }

  const customContainer = Object.assign({}, AmplifyTheme.container, {
    backgroundColor: colors.background,
  })
  const customsectionHeaderText = Object.assign(
    {},
    AmplifyTheme.sectionHeaderText,
    {
      color: colors.primaryText,
      fontFamily: 'Vodafone Rg',
    },
  )
  const customInput = Object.assign({}, AmplifyTheme.input, {
    borderRadius: 10,
    borderWidth: 0,
    height: 58,
    backgroundColor: colors.tertiaryBackground,
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
  })
  const customPasswordInput = Object.assign({}, AmplifyTheme.passwordInput, {
    borderRadius: 10,
    borderWidth: 0,
    backgroundColor: colors.tertiaryBackground,
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
    height: 58,
    paddingRight: 58,
  })
  const customPhoneInput = Object.assign({}, AmplifyTheme.phoneInput, {
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
    borderWidth: 0,
  })
  const customPhoneContainer = Object.assign({}, AmplifyTheme.phoneContainer, {
    borderRadius: 10,
    borderWidth: 0,
    backgroundColor: colors.tertiaryBackground,
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
  })
  const customButton = Object.assign({}, AmplifyTheme.button, {
    backgroundColor: colors.accent,
    borderRadius: 10,
    marginVertical: 15,
    borderWidth: 0,
    color: colors.invertedText,
    fontFamily: 'Vodafone Rg',
  })
  const customButtonDisabled = Object.assign({}, AmplifyTheme.buttonDisabled, {
    backgroundColor: colors.placeholder,
    color: colors.invertedText,
    opacity: 0.5,
    borderRadius: 10,
    marginVertical: 15,
    borderWidth: 0,
    fontFamily: 'Vodafone Rg',
  })
  const customButtontext = Object.assign({}, AmplifyTheme.buttonText, {
    color: colors.invertedText,
    fontFamily: 'Vodafone Rg',
    borderWidth: 0,
  })
  const customSectionFooterLink = Object.assign(
    {},
    AmplifyTheme.sectionFooterLink,
    {
      color: colors.primaryText,
      fontFamily: 'Vodafone Rg',
    },
  )
  const customImagePicker = Object.assign({}, AmplifyTheme.pickerItem, {
    flex: 1,
    height: 80,
    borderRadius: 50,
    borderWidth: 0,
    padding: 8,
    color: colors.primaryText,
    fontSize: 14,
    fontFamily: 'Vodafone Rg',
  })
  const customPicker = Object.assign({}, AmplifyTheme.picker, {
    borderColor: colors.primaryText,
    borderWidth: 0,
    fontSize: 14,
    fontFamily: 'Vodafone Rg',
  })
  const customInputLabel = Object.assign({}, AmplifyTheme.inputLabel, {
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
    fontSize: 14,
  })
  const errorRow = Object.assign({}, AmplifyTheme.errorRow, {
    alignItems: 'center',
  })
  const customSectionFooterLinkDisabled = Object.assign(
    {},
    AmplifyTheme.sectionFooterLinkDisabled,
    {
      color: colors.primaryText,
      fontFamily: 'Vodafone Rg',
      fontSize: 14,
    },
  )
  const customSignedOutMessage = Object.assign(
    {},
    AmplifyTheme.signedOutMessage,
    {
      color: colors.primaryText,
      fontFamily: 'Vodafone Rg',
      fontSize: 14,
    },
  )
  const AuthUiTheme = Object.assign({}, AmplifyTheme, {
    container: customContainer,
    sectionHeaderText: customsectionHeaderText,
    input: customInput,
    passwordInput: customPasswordInput,
    phoneContainer: customPhoneContainer,
    phoneInput: customPhoneInput,
    button: customButton,
    buttonText: customButtontext,
    buttonDisabled: customButtonDisabled,
    sectionFooterLink: customSectionFooterLink,
    pickerItem: customImagePicker,
    inputLabel: customInputLabel,
    picker: customPicker,
    sectionFooterLinkDisabled: customSectionFooterLinkDisabled,
    signedOutMessage: customSignedOutMessage,
    errorRow: errorRow,
  })

  return (
    <>
      {/* <Header
        image={images.back}
        title="header_sign_in"
        onPress={() => goBack()}
        authenticated={false}
      /> */}
      <KeyboardAvoidingView
        style={styles.wrapper}
        behavior={Platform.select({
          ios: 'padding',
          android: undefined,
        })}
      >
        <ScrollView
          style={styles.wrapper}
          contentContainerStyle={styles.contentContainerStyle}
          bounces={false}
          showsVerticalScrollIndicator={false}
        >
          <Auth
            theme={AuthUiTheme}
            signUpConfig={signUpConfig}
            onStateChange={state => onSuccess(state)}
          />
        </ScrollView>
      </KeyboardAvoidingView>
    </>
  )
}

Authenticator.defaultProps = {
  onSuccess: () => { },
  navigation: {
    goBack: () => { },
  },
}

Authenticator.propTypes = {
  onSuccess: PropTypes.func,
  navigation: PropTypes.object,
}

export default Authenticator
