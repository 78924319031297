import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        backgroundColor: colors.white,
        // borderRadius: 7,
        // marginVertical: 3,
        maxHeight: 90,
    },
    isFirst_true: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    isLast_true: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    touchable: {
        flex: 1,
        paddingHorizontal: 15,
        paddingVertical: 12,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 70,
        // flexShrink:0,
        // backgroundColor:'red',
    },
    leftData: {
        flexGrow: 1,
        flexShrink: 1,
        justifyContent: 'center',
        marginRight: 15,
    },
    leftDataContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 24,
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}))

export default styles
