import { Platform } from 'react-native'

import WebPagination from '../WebPagination'

// For web, use our custom component
// For native, we'll need to import the library or create a native version
let Pagination

if (Platform.OS === 'web') {
    Pagination = WebPagination
} else {
    // Import the native library for mobile platforms
    // Pagination = require('@cherry-soft/react-native-basic-pagination').default
}

export default Pagination
