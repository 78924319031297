import React, { useEffect, useRef, useState } from 'react'
import { SafeAreaView, ScrollView, View } from 'react-native'

import { useTheme } from 'react-native-themed-styles'

import { useQuery } from '@apollo/client'

import { isEmpty } from 'lodash'

import moment from 'moment'

import { Header, Loader, Text } from '../../Components'

import Expandable from '../../Components/Expandable/Expandable'

import { GET_INVOICE_BY_FIC_FROM_GOVBOOKS } from '../SalesAndPurchases/SalesAndPurchases.schema'

import { mapCorrectiveAndBadDebtInfo, mapPaymentInfo, mapInvoiceData, mapSameTaxes, mapTotalInfoData } from '../SalesAndPurchases/Helpers'

import { colors, images } from '../../Themes'
import themedStyles, { LineItemStyles } from './SalesAndPurchasesDetails.styles'



const ItemInfoMap = {
    Sasia: 'Sasia',
    'Cmimi pa TVSH': 'Cmimi pa TVSH',
    'Cmimi me TVSH': 'Cmimi me TVSH',
    Zbritje: 'Zbritje',
    'TVSH per artikull': 'TVSH per artikull',
    'Shuma e TVSH': 'Shuma e TVSH',
    'Investim ': 'Investim',
    'Cmimi pas aplikimit te TVSH per artikuj': 'Cmimi pas TVSH ',
    'Perjashtim nga TVSH': 'Perjashtim nga TVSH',
    'Te dhenat e tollonit per artikullin': 'Te dhenat e tollonit per artikullin',
}

const invTransactionTypeConfig = {
    Sales: 'SALES',
    Purchases: 'PURCHASES',
}



function LineItem({ children, style }) {
    const [styles] = useTheme(LineItemStyles)
    return <View style={[styles.container, style]}>
        {children}
    </View>
}

const invoiceSectionMapper = (res) => {
    const mappedObj = {}
    if (isEmpty(res)) {
        mappedObj?.inv
    }
}



export default function SalesAndPurchasesDetails({ navigation, route: { params: { invoiceId, FIC, transactionType } } }) {

    const [styles] = useTheme(themedStyles)
    const { navigate, openDrawer, goBack } = navigation
    const scrollRef = useRef(null)
    const [buyerInfo, setbuyerInfo] = useState({
        Country: '',
        Address: '',
        IDNum: '',
        IDType: '',
        Town: '',
        Name: '',
    })
    const [sellerInfo, setsellerInfo] = useState({
        Country: '',
        Address: '',
        IDNum: '',
        IDType: '',
        Town: '',
        Name: '',
    })
    const [supplyPeriod, setsupplyPeriod] = useState({
        startDate: '',
        endDate: '',
    })

    const [invoiceData, setinvoiceData] = useState({
        InvoiceType: '',
        SelfInvoice: '',
        SimpleInvoice: '',
        Date: '',
        InvoiceNum: '',
        InvoiceOrderNum: '',
        TCRCode: '',
        IssuerInVAT: '',
        OperatorCode: '',
        BusinUnitCode: '',
        SoftwareCode: '',
        IIC: '',
        FIC: '',
        IICSignature: '',
        IsReverseCharge: '',
        PayDeadline: '',
        IsEinvoice: '',
    })
    const [totalInfo, setTotalInfo] = useState({
        TaxFreeAmt: '',
        MarkUpAmt: '',
        GoodsExportAmt: '',
        TotWithoutVAT: '',
        TotVATAmt: '',
        TotPrice: '',
    })
    const [correctiveAndBadDebtInfo, setcorrectiveAndBadDebtInfo] = useState({
        corrective: {
            isCorrective: false,
            IICRef: '',
            IssueDate: '',
            Type: '',
        },
        badDebt: {
            IICRef: '',
            IssueDate: '',
        },
    })

    const [sameTaxesInfo, setSameTaxesInfo] = useState({
        NumberOfItems: '',
        PriceBeforeVAT: '',
        VATRate: '',
        VATAmt: '',
    })

    const [paymentInfo, setPaymentInfo] = useState({
        amount: '',
        method: '',
    })

    const [items, setitems] = useState([])




    const { loading, data, fetchMore } = useQuery(GET_INVOICE_BY_FIC_FROM_GOVBOOKS, {
        variables: {
            invTransactionType: invTransactionTypeConfig[transactionType],
            FIC: FIC,
        },
    })


    useEffect(() => {
        if (data) {
            setbuyerInfo(data?.getInvoiceByFICFromGovBooks?.invoice?.Buyer)
            setsellerInfo(data?.getInvoiceByFICFromGovBooks?.invoice?.Seller)
            setsupplyPeriod(data?.getInvoiceByFICFromGovBooks?.invoice?.SupplyDateOrPeriod)
            setinvoiceData(mapInvoiceData(data))
            setTotalInfo(mapTotalInfoData(data))
            setcorrectiveAndBadDebtInfo(mapCorrectiveAndBadDebtInfo(data))
            setSameTaxesInfo(mapSameTaxes(data))
            setPaymentInfo(mapPaymentInfo(data))
            setitems(data?.getInvoiceByFICFromGovBooks?.invoice?.Items)


        }
    }, [data])


    const BuyerInfoMap = {
        'ID Type': buyerInfo?.IDType || '-',
        Name: buyerInfo?.Name || '-',
        'ID Num': buyerInfo?.IDNum || '-',
        Country: buyerInfo?.Country || '-',
        Address: buyerInfo?.Address || '-',
        Town: buyerInfo?.Town || '-',
    }

    const SellerInfoMap = {
        'ID Type': sellerInfo?.IDType || '-',
        Name: sellerInfo?.Name || '-',
        'ID Num': sellerInfo?.IDNum || '-',
        Country: sellerInfo?.Country || '-',
        Address: sellerInfo?.Address || '-',
        Town: sellerInfo?.Town || '-',
    }

    const SupplyPeriodMap = {
        Start: moment(supplyPeriod?.startDate).format('DD/MM/YYYY') || '-',
        End: moment(supplyPeriod?.endDate).format('DD/MM/YYYY') || '-',
    }

    console.log(supplyPeriod, ' supplyperiod')

    const InvoiceInfoMap = {
        'Transaction Type': transactionType || '-',
        'Busin Unit Code': invoiceData?.BusinUnitCode || '-',
        'FIC': invoiceData?.FIC || '-',
        'IIC': invoiceData?.IIC || '-',
        'Invoice Num': invoiceData?.InvoiceNum || '-',
        'Invoice Order Num': invoiceData?.InvoiceOrderNum || '-',
        'Is Einvoice': invoiceData?.IsEinvoice || '-',
        'Is Issuer In VAT': invoiceData?.IssuerInVAT || '-',
        'Is Reverse Charge': invoiceData?.IsReverseCharge || '-',
        InvoiceType: invoiceData?.InvoiceType || '-',
        'Self-invoice': invoiceData?.SelfInvoice || '-',
        'Is Simplified Inv': invoiceData?.SimpleInvoice || '-',
        'Issue Date Time:': moment(invoiceData?.Date).format('DD/MM/YYYY') || '-',
        'Operator Code': invoiceData?.OperatorCode || '-',
        'Pay Deadline': moment(invoiceData?.PayDeadline).format('DD/MM/YYYY') || '-',
        'Software Code': invoiceData?.SoftwareCode || '-',
        'TCR Code': invoiceData?.TCRCode || '-',
        // 'IIC Signature': invoiceData?.IICSignature || '-',
    }
    const TotalInfoMap = {
        'Tax Free Amt': '-',
        'Mark Up Amt': '-',
        'Goods Export Amt': '-',
        'Tot Without VAT': totalInfo?.TotWithoutVAT || '-',
        'Tot VAT Amt': totalInfo?.TotVATAmt || '-',
        'Tot Price': totalInfo?.TotPrice || '-',
    }

    const CorrectiveInvoiceInfoMap = {
        'IIC Ref': correctiveAndBadDebtInfo?.corrective?.IICRef || '-',
        'Issue Date': moment(correctiveAndBadDebtInfo?.corrective?.IssueDate).format('DD/MM/YYYY') || '-',
    }
    const BadDebtInvoiceInfoMap = {
        'IIC Ref': correctiveAndBadDebtInfo?.badDebt?.IICRef || '-',
        'Issue Date': moment(correctiveAndBadDebtInfo?.badDebt?.IssueDate).format('DD/MM/YYYY') || '-',
    }
    const SameTaxesInfoMap = {
        'Number of Items': sameTaxesInfo?.NumberOfItems || '-',
        'Price Before VAT': sameTaxesInfo?.PriceBeforeVAT || '-',
        'VAT Rate': sameTaxesInfo?.VATRate || '-',
        'VAT Amt': sameTaxesInfo?.VATAmt || '-',
    }


    const PaymentInfoMap = {
        'Amount': paymentInfo?.amount || '-',
        'Method': paymentInfo?.method || '-',
    }


    const renderInfo = (configObject, title, customTitle = false) => {
        if (isEmpty(configObject)) { return null }
        const keys = Object.keys(configObject)
        return (
            <View style={styles.noteSectionContainer} >
                <Expandable iconSize={20} size={'body'} containerStyle={styles.expandableContainer} {...(customTitle ? { customTitle: customTitle } : {})} title={title} >
                    <View style={styles.separator} />
                    {keys?.map(key => {
                        return (
                            <>
                                <LineItem >
                                    <Text i18nKey={key} weight={'light'} size={'footnote'} />
                                    <Text i18nKey={configObject[key]} size={'footnote'} />
                                </LineItem>
                                <View style={styles.separator} />
                            </>
                        )
                    })}

                </Expandable>
            </View>
        )
    }

    const renderItemsInfo = (configObject, customTitle) => {
        const keys = Object.keys(configObject)
        return (
            <View style={styles.noteSectionContainer} >
                <Expandable size={'body'} iconSize={20} containerStyle={[styles.expandableContainer, { paddingVertical: 6 }]} {...(customTitle ? { customTitle: customTitle } : {})} >
                    <View style={[styles.separator, { marginHorizontal: 6 }]} />
                    <View style={{ marginHorizontal: 6, marginBottom: 0, paddingTop: 3, backgroundColor: colors.background }}>

                        {keys?.map(key => {
                            return (
                                <>
                                    <LineItem  >
                                        <Text i18nKey={key} weight={'light'} size={'footnote'} />
                                        <Text i18nKey={configObject[key]} size={'footnote'} />
                                    </LineItem>
                                    <View style={styles.separator} />
                                </>
                            )
                        })}
                    </View>

                </Expandable>
            </View>
        )
    }

    console.log(correctiveAndBadDebtInfo?.corrective?.isCorrective, ' lsjdksxhdjsdjhgvb')

    const renderItems = () => {
        return (

            <View style={styles.itemsContainer}>
                <Text i18nKey="Items" style={{ padding: 8 }} size={'body'} weight="bold" />
                <View style={styles.separator} />
                {items?.map(item => {
                    const { ItemName, Quantity, UnitPriceAftVAT, currency = 'ALL', PriceAftrVAT } = item
                    const customItemExpandableTitle = (
                        <LineItem>
                            <View>
                                <Text i18nKey={ItemName} />
                                <Text i18nKey={`${Quantity} + ${UnitPriceAftVAT} ${currency}`} />
                            </View>
                            <Text i18nKey={PriceAftrVAT} />
                        </LineItem>)

                    const ConfigObject = {
                        Sasia: item?.Quantity,
                        'Cmimi pa TVSH': item?.UnitPriceBefVAT || '-',
                        'Cmimi me TVSH': item?.UnitPriceAftVAT || '-',
                        Zbritje: '-',
                        'TVSH per artikull': item?.VATRate || '-',
                        'Shuma e TVSH': item?.VATAmount || '-',
                        // 'Investim': '-',
                        'Cmimi pas aplikimit te TVSH per artikuj': item?.PriceAftrVAT || '-',
                        'Perjashtim nga TVSH': '-',
                        // 'Te dhenat e tollonit per artikullin': '-',
                    }

                    return (<>
                        {renderItemsInfo(ConfigObject, customItemExpandableTitle)}
                        <View style={styles.separator} />

                    </>
                    )
                })}

            </View>
        )
    }

    if (loading) {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Loader size={64} />
            </View>
        )
    }

    if (!loading && (!data.getInvoiceByFICFromGovBooks)) {
        return (
            <SafeAreaView style={styles.container}>
                <Header
                    image={images.closeIcon}
                    loading={false}
                    title={invoiceId || 'Details'}
                    onPress={() => goBack()}
                />
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text i18nKey={'There was a problem retrieving details for this invoice.'} />
                </View>
            </SafeAreaView>
        )
    }

    return (
        <SafeAreaView style={styles.container}>
            <Header
                image={images.closeIcon}
                loading={false}
                title={invoiceId || 'Details'}
                onPress={() => goBack()}
            />
            <ScrollView style={{ flex: 1, paddingHorizontal: 10 }} ref={scrollRef}>
                {renderInfo(InvoiceInfoMap, 'Invoice info')}
                {renderInfo(TotalInfoMap, 'Total')}
                {correctiveAndBadDebtInfo?.corrective?.isCorrective && renderInfo(CorrectiveInvoiceInfoMap, 'Corrective Invoice')}
                {/* {renderInfo(BadDebtInvoiceInfoMap, 'Bad Debt Invoice')} */}
                {renderItems()}
                {renderInfo(PaymentInfoMap, 'Payment Methods')}
                {renderInfo(SellerInfoMap, 'Seller')}
                {renderInfo(BuyerInfoMap, 'Buyer')}
                {sameTaxesInfo && renderInfo(SameTaxesInfoMap, 'Same Taxes')}
                {!isEmpty(supplyPeriod) && renderInfo(SupplyPeriodMap, 'Supply Period')}
                <View style={{ height: 30 }} />



            </ScrollView>


        </SafeAreaView>
    )
}
