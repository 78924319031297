import { Platform } from 'react-native'

const amplifyConfig = {
  Auth: {
    identityPoolId: 'eu-central-1:8eb8950d-1415-4952-9af9-246d32e52019',
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    // DEV
    // userPoolId: 'eu-central-1_War7GuG5q',
    // userPoolWebClientId: Platform.OS === 'web' ? '5nfjot7um38u507b4l2rv4q143' : '3mbefqgt8l3kcf70a02403hgm8',
    // PROD
    userPoolId: 'eu-central-1_0PX5B73Ox',
    userPoolWebClientId: Platform.OS === 'web' ? '4om2esuqkecapnsfu1v1q4nhek' : '6v523l2je18fh9ln2n8c8h6sgf',
  },
  Analytics: {
    disabled: true,
  },
}

export default amplifyConfig
