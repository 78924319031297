import React, { memo, useContext, useEffect, useState, useCallback } from 'react'
import { useTheme } from 'react-native-themed-styles'

import { SafeAreaView } from 'react-native-safe-area-context'

import { SectionList, TouchableOpacity, View, Dimensions } from 'react-native'


import PropTypes from 'prop-types'

import ContentLoader, { Rect, Circle, Path } from 'react-content-loader/native'



import { useLazyQuery, useQuery } from '@apollo/client'

import moment from 'moment'

import { isEmpty, isNull } from 'lodash'

import { useFocusEffect } from '@react-navigation/native'

import Pagination from '../../Components/Pagination'

import { Calendar, Header, Icon, Loader, PeriodCalendar, Text } from '../../Components'

import TouchableItemWithIcon from '../../Components/TouchableItemWithIcon/TouchableItemWithIcon'

import themedStyles, { initStyles, itemSeparatorStyles, ordersList } from './SalesAndPurchases.styles'
import { colors, images } from '../../Themes'
import Theme from '../../Themes/Theme'
import { DUMMY_SECTION_DATA, GovInvoicesMapper, mapPageInfo } from './Helpers'
import { LIST_INVOICES_FROM_GOVBOOKS } from './SalesAndPurchases.schema'


const headerConfig = {
    Init: {
        label: 'Sales & Purchases',
        icon: images.menu,
    },
    Sales: {
        label: 'Sales',
        icon: images.chevronLeft,
    },
    Purchases: {
        label: 'Purchases',
        icon: images.chevronLeft,
    },
}

const processTypesEnum = {
    Init: 'Init',
    Sales: 'Sales',
    Purchases: 'Purchases',
}
const invTransactionTypeConfig = {
    Sales: 'SALES',
    Purchases: 'PURCHASES',
}

const windowWidth = Dimensions.get('window').width
const array = [1, 2, 3, 4]
const MyLoader = (props) => (
    <ContentLoader
        speed={2}
        width={windowWidth}
        height={75}
        viewBox="0 0 400 75"
        backgroundColor={colors.disabled}
        foregroundColor="#ffffff"
        {...props}
    >
        <Rect x="48" y="24" rx="7" ry="7" width="158" height="9" />
        <Rect x="12" y="24" rx="7" ry="7" width="24" height="24" />
        <Rect x="48" y="36" rx="7" ry="7" width="102" height="9" />
        <Rect x="283" y="24" rx="7" ry="7" width="60" height="24" />
    </ContentLoader>
)


function Item({
    onPress,
    image,
    title,
    icon,
    style,
    time = '',
    email = '',
    expectedCash = '',
    isFirst,
    isLast,
    withDescription = false,
}) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    return (
        <TouchableOpacity
            onPress={onPress}
            style={[
                styles.listInfoContainer(withDescription),
                style,
                styles['isFirst_' + isFirst],
                styles['isLast_' + isLast],
            ]}
        >
            <Icon source={image} size={24} style={styles.rightSpacing} />
            <View style={styles.titleContainer}>
                <Text isLoading={false} size={email ? 'footnote' : 'body'} i18nKey={title} />
                {time && <Text isLoading={false} size={'note'} color={colors.secondaryText} i18nKey={time} />}
                {email && <Text isLoading={false} size={'note'} color={colors.secondaryText} i18nKey={email} />}
            </View>
            <View style={styles.expectedCashContainer}>
                {expectedCash && <Text isLoading={false} size="body" i18nKey={expectedCash} />}
                <Icon source={icon} size={24} color={colors.accent} />
            </View>
        </TouchableOpacity>
    )
}

Item.propTypes = {
    onPress: PropTypes.func,
    image: PropTypes.any,
    title: PropTypes.string,
    icon: PropTypes.any,
    style: PropTypes.object,
}

function ItemSeparator(orientation = 'horizontal') {
    const [styles] = useTheme(itemSeparatorStyles)

    if (orientation === 'vertical') {
        return <View style={styles.verticalContainer}><View style={styles.vertical} /></View>
    }
    return <View style={styles.horizontal} />
}

function ListEmpty() {
    return (<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><Text i18nKey={'Empty'} /></View>)
}



const OrdersList = memo(({ step, navigate }) => {
    const [styles] = useTheme(ordersList)
    const [page, setPage] = useState(1)
    const [selectedDates, setselectedDates] = useState('')
    const [mappedInvoices, setmappedInvoices] = useState([])
    const [hasSearched, sethasSearched] = useState(false)
    const [pageInfo, setpageInfo] = useState({
        currentPage: 1,
        maxPage: 1,
        perPage: 1,
    })
    const [isOpened, setIsOpend] = useState(false)
    const updateCurrentPage = (newPage) => {
        setpageInfo((prevPageInfo) => ({
            ...prevPageInfo,
            currentPage: newPage,
        }))
    }


    const renderSectionHeader = ({ section }) => {
        const { sectionTitle, key } = section
        const isFirst = key === 0
        if (sectionTitle) {
            return (
                <Text
                    size="footnote"
                    weight="regular"
                    style={styles.sectionHeader(isFirst)}
                    i18nKey={sectionTitle}
                />
            )
        }
        return null
    }
    const keyExtractor = (_, index) => 'item-' + index
    const renderItem = ({ item, index, section }) => {
        return (
            <Item
                {...item}
                image={images.doc}
                icon={images.chevronRight}
                isFirst={index === 0}
                isLast={index === section.data.length - 1}
                onPress={() => navigate('SalesAndPurchasesDetails', { invoiceId: item?.id, FIC: item?.FIC, transactionType: step })}
            />
        )
    }

    const [listInvoices, { loading, data: listData, refetch, fetchMore, error }] = useLazyQuery(LIST_INVOICES_FROM_GOVBOOKS, {
    })



    useFocusEffect(
        useCallback(() => {
            if (hasSearched) {
                refetch()
            }
        }, [])
    )


    useEffect(() => {
        if (listData?.listInvoicesFromGovBooks) {
            const mappedData = GovInvoicesMapper(listData)
            setmappedInvoices(mappedData)
            const mappedOtherInfo = mapPageInfo(listData)
            setpageInfo(mappedOtherInfo)

        } else {
            setmappedInvoices([])
        }

    }, [listData])


    const onDayPress = (date) => {
        setselectedDates(date)

        if (!isNull(date)) {
            sethasSearched(true)
            listInvoices({
                variables: {
                    date: date,
                    invTransactionType: invTransactionTypeConfig[step],
                    page: page,
                },
            })
        } else {

            sethasSearched(false)
            setmappedInvoices([])
            setselectedDates('')
        }

    }

    // const onClearSearchPress = () => {
    //     console.log('setting')
    //     sethasSearched(false)
    //     setmappedInvoices([])
    //     setselectedDates('')
    // }

    return (
        <>
            <Text i18nKey={`Search to get ${step?.toLowerCase()} invoice results`} />
            <View style={styles.searchContainer}>
                {/* <PeriodCalendar
                    placeholder="Search..."
                    onSearch={onDayPress}
                    onIntervalChange={setselectedDates}
                    onSearchClean={onClearSearchPress}
                    style={{ paddingHorizontal: 0, marginHorizontal: 0, marginTop: 8, marginBottom: 14 }}
                /> */}
                <Calendar
                    selectedDay={selectedDates}
                    onDayPress={onDayPress}
                    placeholder={'Select a date to find invoices'}
                    setIsOpend={setIsOpend}
                // onSearchClean={onClearSearchPress}

                />
            </View>
            {hasSearched && !loading && <>
                <SectionList
                    // ref={(ref) => (listRef.current = ref)}
                    onMomentumScrollEnd={() => { }}
                    showsVerticalScrollIndicator={false}
                    stickySectionHeadersEnabled={false}
                    style={{ marginTop: 50 }}
                    contentContainerStyle={styles.contentContainer(isOpened)}
                    ItemSeparatorComponent={ItemSeparator}
                    ListEmptyComponent={!loading && hasSearched && ListEmpty}
                    renderSectionHeader={renderSectionHeader}
                    keyExtractor={keyExtractor}
                    sections={mappedInvoices}
                    renderItem={renderItem}
                    refreshing={false}
                    onRefresh={() => { }}
                    onEndReached={() => { }}
                    onEndReachedThreshold={0.2}

                    ListFooterComponent={
                        <View style={styles.footer}>
                            {false ? <Loader size={32} /> : null}
                        </View>
                    }
                />

                <Pagination
                    btnStyle={{ paddingVertical: 3, paddingHorizontal: 8, borderRadius: 7, backgroundColor: colors.background, borderWidth: 1, borderColor: colors.disabled }}
                    containerStyle={{}}
                    textStyle={{ color: colors.secondaryText }}
                    activeBtnStyle={{ backgroundColor: colors.secondaryAccent }}
                    activeTextStyle={{ color: colors.white }}
                    totalItems={Number(pageInfo?.maxPage) * Number(pageInfo?.perPage)}
                    pageSize={pageInfo?.perPage}
                    currentPage={pageInfo?.currentPage}
                    onPageChange={updateCurrentPage}
                    rightButtonImage={images.chevronRight}
                    leftButtonImage={images.chevronLeft}

                />
            </>
            }
            {
                loading &&
                <View style={{ backgroundColor: colors.tertiaryBackground, marginTop: 66, borderRadius: 7 }}>

                    {array.map((nr) => {
                        return (
                            <>
                                <MyLoader />
                                <ItemSeparator />
                            </>
                        )
                    })}
                </View>
            }
        </>
    )
})


function Init({ setstep }) {
    const [styles] = useTheme(initStyles)

    return (
        <View View >
            <Item style={styles.initLineItem} withDescription title={'Sales'} image={images.Sales} icon={images.chevronRight} onPress={() => setstep(processTypesEnum.Sales)} />
            <Item style={styles.initLineItem} withDescription title={'Purchases'} image={images.Purchases} icon={images.chevronRight} onPress={() => setstep(processTypesEnum.Purchases)} />
        </View >
    )
}


function SalesAndPurchases({ navigation: { navigate, openDrawer } }) {
    const ScreensConfig = {
        Init: Init,
        Sales: OrdersList,
        Purchases: OrdersList,
    }
    const [styles] = useTheme(themedStyles)
    const [step, setstep] = useState(processTypesEnum.Init)
    const Screen = ScreensConfig[step]
    const headerOnPress = {
        Init: openDrawer,
        Sales: () => { setstep(processTypesEnum.Init) },
        Purchases: () => { setstep(processTypesEnum.Init) },

    }

    return (
        <SafeAreaView style={styles.container}>
            <Header title={headerConfig[step]?.label} image={headerConfig[step].icon} onPress={headerOnPress[step]} />
            <View style={{ paddingHorizontal: 10, flex: 1 }} >
                <Screen setstep={setstep} step={step} navigate={navigate} />
            </View>
        </SafeAreaView >
    )
}


export default SalesAndPurchases
