import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native'
import PropTypes from 'prop-types'

const WebPagination = ({
    totalItems,
    pageSize,
    currentPage,
    onPageChange,
    btnStyle,
    containerStyle,
    textStyle,
    activeBtnStyle,
    activeTextStyle,
    maxVisibleButtons = 5,
}) => {
    const totalPages = Math.ceil(totalItems / pageSize)

    // Don't show pagination if only one page
    if (totalPages <= 1) { return null }

    // Calculate range of visible page buttons
    const getVisiblePageNumbers = () => {
        let startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2))
        const endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1)

        // Adjust if we're near the end
        if (endPage - startPage + 1 < maxVisibleButtons) {
            startPage = Math.max(1, endPage - maxVisibleButtons + 1)
        }

        const pages = []
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i)
        }
        return pages
    }

    const visiblePages = getVisiblePageNumbers()

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages && newPage !== currentPage) {
            onPageChange(newPage)
        }
    }

    return (
        <View style={[styles.container, containerStyle]}>
            {/* Previous button */}
            <TouchableOpacity
                onPress={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={[
                    styles.button,
                    btnStyle,
                    currentPage === 1 && styles.disabledButton,
                ]}
            >
                <Text style={[
                    styles.text,
                    textStyle,
                    currentPage === 1 && styles.disabledText,
                ]}>
                    {'<'}
                </Text>
            </TouchableOpacity>

            {/* First page button if not visible */}
            {visiblePages[0] > 1 && (
                <>
                    <TouchableOpacity
                        onPress={() => handlePageChange(1)}
                        style={[styles.button, btnStyle]}
                    >
                        <Text style={[styles.text, textStyle]}>1</Text>
                    </TouchableOpacity>

                    {visiblePages[0] > 2 && (
                        <Text style={[styles.ellipsis, textStyle]}>...</Text>
                    )}
                </>
            )}

            {/* Page buttons */}
            {visiblePages.map(page => (
                <TouchableOpacity
                    key={page}
                    onPress={() => handlePageChange(page)}
                    style={[
                        styles.button,
                        btnStyle,
                        page === currentPage && [styles.activeButton, activeBtnStyle],
                    ]}
                >
                    <Text style={[
                        styles.text,
                        textStyle,
                        page === currentPage && [styles.activeText, activeTextStyle],
                    ]}>
                        {page}
                    </Text>
                </TouchableOpacity>
            ))}

            {/* Last page button if not visible */}
            {visiblePages[visiblePages.length - 1] < totalPages && (
                <>
                    {visiblePages[visiblePages.length - 1] < totalPages - 1 && (
                        <Text style={[styles.ellipsis, textStyle]}>...</Text>
                    )}

                    <TouchableOpacity
                        onPress={() => handlePageChange(totalPages)}
                        style={[styles.button, btnStyle]}
                    >
                        <Text style={[styles.text, textStyle]}>{totalPages}</Text>
                    </TouchableOpacity>
                </>
            )}

            {/* Next button */}
            <TouchableOpacity
                onPress={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={[
                    styles.button,
                    btnStyle,
                    currentPage === totalPages && styles.disabledButton,
                ]}
            >
                <Text style={[
                    styles.text,
                    textStyle,
                    currentPage === totalPages && styles.disabledText,
                ]}>
                    {'>'}
                </Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 10,
        flexWrap: 'wrap',
    },
    button: {
        marginHorizontal: 4,
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 4,
        minWidth: 35,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        textAlign: 'center',
    },
    activeButton: {
        backgroundColor: '#007bff',
    },
    activeText: {
        color: '#ffffff',
    },
    disabledButton: {
        opacity: 0.5,
    },
    disabledText: {
        opacity: 0.5,
    },
    ellipsis: {
        marginHorizontal: 4,
    },
})

WebPagination.propTypes = {
    totalItems: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    btnStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    textStyle: PropTypes.object,
    activeBtnStyle: PropTypes.object,
    activeTextStyle: PropTypes.object,
    maxVisibleButtons: PropTypes.number,
}

export default WebPagination
