import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flexGrow: 1,
        flex: 1,
        backgroundColor: colors.background,
    },
    separator: {
        height: 1,
        backgroundColor: colors.separator,
    },
    noteSectionContainer: {
        backgroundColor: colors.tertiaryBackground,
        marginVertical: 8,
        borderRadius: 7,
    },
    expandableContainer: {
        paddingVertical: 16,
        paddingHorizontal: 8,
    },
    itemsContainer: {
        backgroundColor: colors.tertiaryBackground,
        marginVertical: 8,
        borderRadius: 7,
    },

}))
const LineItemStyles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 8,
        paddingVertical: 6,
    },

}))

export { LineItemStyles }
export default styles
