import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flexGrow: 1,
        flex: 1,
        backgroundColor: colors.background,
    },
    listInfoContainer: function (withDescription) {
        const style = {
            backgroundColor: colors.tertiaryBackground,
            paddingHorizontal: 12,
            paddingVertical: 6,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between', // Add this line
            flexWrap: 'wrap', // Allow wrapping
        }
        if (withDescription) {
            style.borderRadius = 7
        }
        return style
    },
    rightSpacing: { marginRight: 20 },
    titleContainer: {
        flexGrow: 1,
        flexShrink: 1, // Allow to shrink to fit the container
        flexBasis: 'auto', // Adjust to the content
    },
    expectedCashContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end', // Align to the end
        flexShrink: 0, // Do not allow shrinking
    },
    isFirst_true: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    isLast_true: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
}))

const ordersList = styleSheetFactory(colors => ({
    searchContainer: {
        padding: 0,
        margin: 0,
    },
    sectionHeader: function (isFirst) {
        return {
            marginTop: isFirst ? 8 : 24,
            marginBottom: 8,
        }
    },
    listContainer: {
        position: 'relative',
        flexGrow: 1,
        flex: 1,
    },
    contentContainer: (isCalendarOpened) => ({
        borderRadius: 7,
        overflow: 'hidden',
        marginBottom: 15,
        marginTop: isCalendarOpened ? 350 : undefined,
        // paddingHorizontal: 12,
    }),
    footer: {
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export const listEmptyStyles = styleSheetFactory(colors => ({
    container: {
        flexGrow: 1,
        alignItems: 'center',
        padding: 15,
        paddingTop: 20,
    },
}))


export const itemSeparatorStyles = styleSheetFactory(colors => ({
    horizontal: {
        // flex: 1,
        height: 1,
        backgroundColor: colors.separator,
    },

}))


export const initStyles = styleSheetFactory(colors => ({
    initLineItem: {
        paddingVertical: 19,
        paddingHorizontal: 15,
        marginTop: 16,
    },

}))



export const itemStyles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        height: 70,
        backgroundColor: colors.tertiaryBackground,
        // borderRadius: 7,
        // marginVertical: 3,
    },
    isFirst_true: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    isLast_true: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    touchable: {
        flex: 1,
        paddingHorizontal: 15,
        paddingVertical: 12,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 70,
        // flexShrink:0,
        // backgroundColor:'red',
    },
    leftData: {
        flexGrow: 1,
        flexShrink: 1,
        justifyContent: 'center',
        marginRight: 15,
    },
    icon: {
        tintColor: colors.primaryIcon,
        marginLeft: 8,
    },

}))

export { ordersList }

export default styles
