import React, { useState, useContext } from 'react'
import { Animated, View, TouchableOpacity, Platform } from 'react-native'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Calendar as CalendarSelector,
  LocaleConfig,
} from 'react-native-calendars'
import { useTheme } from 'react-native-themed-styles'

import { useLanguage } from '../../Utils/Language'

import Icon from '../Icon'
import Text from '../Text'
import TextInput from '../TextInput'
import { calendarDict } from '../../Utils/Dict'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './Calendar.Styles'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)
const LANGUAGES = {
  en: calendarDict.en,
  al: calendarDict.al,
}

const options = {
  arrows: {
    left: images.arrow_left,
    right: images.arrow_right,
  },
  scale: {
    true: 0,
    false: 1,
  },
  touchable: {
    true: 'auto',
    false: 'none',
  },
}

function RenderArrow(direction) {
  return (
    <Icon
      source={options.arrows[direction]}
      size={12}
    />
  )
}


function Calendar(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const calendarTheme = {
    selectedDayBackgroundColor: colors.calendarAccent,
    todayTextColor: colors.calendarAccent,
    textDayHeaderFontFamily: 'Vodafone Rg',
    textDayFontFamily: 'Vodafone Rg',
    textMonthFontFamily: 'Vodafone Rg',
    'stylesheet.calendar.main': {
      container: {
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: colors.tertiaryBackground,
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
      },
      monthView: {
        paddingVertical: 3,
      },
      week: {
        marginTop: 0,
        marginBottom: 0,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
      },
    },
    'stylesheet.calendar.header': {
      header: {
        flexDirection: 'row',
        height: 40,
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 0,
        alignItems: 'center',
        backgroundColor: colors.tertiaryBackground,
      },
      monthText: {
        fontSize: 18,
        ...Platform.select({
          ios: {
            fontFamily: 'Vodafone Rg',
            fontWeight: 'bold',
          },
          android: {
            fontFamily: 'Vodafone Rg Bold',
          },
        }),
        color: colors.primaryText,
        margin: 0,
      },
      arrow: {
        padding: 0,
      },
      arrowImage: {
        tintColor: colors.primaryText,
      },
      disabledArrowImage: {
        tintColor: colors.placeholder,
      },
      week: {
        backgroundColor: colors.tertiaryBackground,
        height: 40,
        marginTop: 0,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      },
      dayHeader: {
        marginTop: 0,
        marginBottom: 0,
        textAlign: 'center',
        fontSize: 12,
        fontFamily: 'Vodafone Rg',
        color: colors.primaryText,
        flex: 1,
      },
    },
    'stylesheet.day.basic': {
      base: {
        minWidth: 42,
        height: 42,
        borderRadius: 21,
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center',
      },
      text: {
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
        ...Platform.select({
          ios: {
            fontFamily: 'Vodafone Rg',
            fontWeight: 'bold',
          },
          android: {
            fontFamily: 'Vodafone Rg Bold',
          },
        }),
        color: colors.primaryText,
      },
      selected: {
        backgroundColor: colors.calendarAccentRGBA(),
      },
      today: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.calendarAccent,
      },
      todayText: {
        color: colors.calendarAccent,
      },
      selectedText: {
        color: colors.calendarText,
      },
      disabledText: {
        color: colors.disabled,
        fontFamily: 'Vodafone Rg',
      },
    },
  }
  const {
    selectedDay,
    onDayPress,
    onChangeText,
    value,
    withInput,
    onSearchClean,
    removeSelectedDate,
    setIsOpend = () => { }
  } = props

  const today = moment().format('YYYY-MM-DD')

  const [open, setOpen] = useState(false)
  const [anim] = useState(new Animated.Value(0))
  const [calendarHeight, setCalendarHeight] = useState(100)
  const [markedDates, setMarkedDates] = useState({})

  const getCalendarDimensions = dimensions => {
    setCalendarHeight(dimensions.height / 2)
  }

  LocaleConfig.locales.en = LANGUAGES[lang]
  LocaleConfig.defaultLocale = 'en'


  const animate = () => {
    Animated.timing(anim, {
      toValue: options.scale[open],
      duration: 200,
      useNativeDriver: true,
    }).start()
  }

  const toggleCalendar = () => {
    setOpen(!open)
    animate()
    if (setIsOpend) {
      setIsOpend(prev => !prev)
    }
  }

  const handleDayPress = day => {
    toggleCalendar()
    onDayPress(day)
    setMarkedDates({ [day]: { selected: true } })
  }

  const removeSelectedDay = () => {
    onDayPress(null)
    setMarkedDates({})
  }


  return (
    <View style={styles.container}>
      <View style={styles.touchableBackdrop} />
      <TouchableOpacity
        style={[styles.touchable, styles[`touchable_${open}`]]}
        onPress={toggleCalendar}
        activeOpacity={0.5}>
        <View
          style={[
            styles.calendarIconContainer,
            styles[`calendarIconContainer_${open}`],
          ]}>
          <Icon source={images.calendar} size={20} color={colors.secondaryIcon} />
        </View>
        <View style={styles.searchResultContainer}>
          {withInput ? <TextInput
            placeholder={selectedDay ? selectedDay : props.placeholder}
            editable={selectedDay ? false : true}
            keyboardType="default"
            placeholderTextColor={colors.placeholder}
            style={styles.input}
            onChangeText={onChangeText}
            value={value}
          /> : <Text color={colors.placeholder} i18nKey={selectedDay || props.placeholder} />}
        </View>
        {!!value &&
          <TouchableOpacity onPress={onSearchClean} style={styles.removeSelected}>
            <Icon source={images.close} size={15} color={colors.midGrey} />
          </TouchableOpacity>
        }
        {Boolean(selectedDay) && (
          <TouchableOpacity
            style={styles.removeSelected}
            onPress={() => {
              removeSelectedDay()
              removeSelectedDate()
            }}>
            <Icon source={images.close} size={15} color={colors.midGrey} />
          </TouchableOpacity>
        )}
      </TouchableOpacity>
      <View
        style={[
          styles.calendarToggleBackdrop,
          styles[`calendarToggleBackdrop_${open}`],
        ]}
      />
      <Animated.View
        onLayout={event => getCalendarDimensions(event.nativeEvent.layout)}
        style={[
          styles.calendarContainer,
          styles[`calendarContainer_${open}`],
          {
            transform: [
              {
                translateY: anim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [-calendarHeight, 0],
                }),
              },
              { scaleY: anim },
            ],
            opacity: anim.interpolate({
              inputRange: [0, 0.5, 0.8, 1],
              outputRange: [0, 0, 1, 1],
            }),
          },
        ]}
        pointerEvents={options.touchable[open]}>
        <CalendarSelector
          maxDate={today}
          // displayLoadingIndicator={true}
          onDayPress={day => handleDayPress(day.dateString)}
          // Formatting values: http://arshaw.com/xdate/#Formatting
          monthFormat={'MMM yyyy'}
          markedDates={markedDates}
          // firstDay=1 week starts from Monday.
          //Note that dayNames and dayNamesShort should still start from Sunday.
          firstDay={1}
          renderArrow={RenderArrow}
          theme={calendarTheme}
        />
      </Animated.View>
      {open && (
        <AnimatedTouchable
          onPress={toggleCalendar}
          style={[
            styles.backdrop,
            {
              opacity: anim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 1],
              }),
            },
          ]}
          activeOpacity={1}
        />
      )}
    </View>
  )
}

Calendar.defaultProps = {
  onDayPress: () => { },
  placeholder: '',
  onChangeText: () => { },
  onSearchClean: () => { },
  removeSelectedDate: () => { },
}

Calendar.propTypes = {
  selectedDay: PropTypes.string,
  onDayPress: PropTypes.func,
  placeholder: PropTypes.string,
  onChangeText: PropTypes.func,
  value: PropTypes.string,
  withInput: PropTypes.bool,
  onSearchClean: PropTypes.func,
  removeSelectedDate: PropTypes.func,
}

export default Calendar
export {
  RenderArrow,
}
